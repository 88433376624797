@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

.buttons {
  display: flex;
  justify-content: center;
  width: 100%;
  top: 60%;
  position: absolute;
  transition: top 1s;

  &.active {
    top: 80%;
  }

  input {
    display: none;
  }
}

#headline{
  display: flex;
  justify-content: center;
  top: 27.5%;
  position: absolute;
  width: 100%;
  font-size: 3.5vw;
  font-family: Pacifico, serif;
  color: #FFF;
  transition: top 1s;
  &.active{
    top: 22.5%;
  }
}

.link {
  background-color: #18baf560;
  transition: background-color 0.5s;

  &:hover {
    background-color: #009ad560;
  }
}

.image{
  background-color: #00BB0060;
  transition: background-color 0.5s;
  &:hover{
    background-color: #00990060;
  }
}

.ul{
  padding: 1.3vw 2.5vw;
  border: none;
  border-radius: 8px;
  text-align: center;
  display: inline-block;
  font-size: 2vw;
  text-decoration: none;
  color: white;
  cursor: pointer;
}

body{
  margin: 0;
  padding: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("assets/cosmos.jpg");
}

.linkfield{
  display: flex;
  justify-content: center;
  width: 100%;
  top: 50%;
  position: absolute;
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s;

  &.active{
    opacity: 100;
    pointer-events: auto;
  }
}

.stf{
  padding: 1.3vw 2.5vw;
  color:white;
  border: 3px solid #3333ff60;
  border-radius: 8px;
  font-size: 2vw;
  margin-right: 2em;
  background-color: #55555560;
}

.sbt{
  padding: 1.3vw 2.5vw;
  border: 3px solid #55555560;
  background-color: #3333ff60;
  color: white;
  border-radius: 8px;
  font-size: 2vw;
  cursor: pointer;
  transition: background-color 0.5s;
  &:hover{
    background-color: #4444ff60;
  }
}
